import styled from 'styled-components'

export const Title = styled.h1`
    color: #043923;
    text-transform: uppercase;
    margin: 30px auto 10px auto;
`

export const Description = styled.p`
    color: #3E554B;
    font-size: 15px;
    line-height: 22px;
`
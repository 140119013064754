import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Button, CopyButton } from '../../components/UI/Button/button'
import AuthWrapper from '../../containers/AuthWrapper/AuthWrapper'
import WalletWrapper from '../../containers/WalletWrapper/WalletWrapper'
import * as Styles from '../../components/UI/WalletShared/walletShared'
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import { createAlgorandWallet, getActiveAlgorandWallet } from '../../app/algorand/algorandSlice'
import Modal from '../../components/UI/Modal/Modal'
import useDisclaimer from '../../Hooks/Disclaimer'
import { DisclaimerDefault, DisclaimerError, DisclaimerSuccess } from '../../components/Disclaimer/Disclaimer'
import { IMPORT } from '../../constants/walletStatus'
import { DEFAULT, ERROR, SUCCESS } from '../../constants/modalStatus'
import { ALGO } from '../../constants/network'
import useModal from '../../Hooks/Modal'
import useSubmit from '../../Hooks/Submit'
import useFormValidity from '../../Hooks/FormValidity'
import DB from '../../app/db'
import useEncrypt from '../../Hooks/Encrypt'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'


function ImportWallet() {
    const dispatch = useDispatch()
    const history = useHistory()
    const [missingWords, setMissingWords] = useState(Array(25).fill(''))
    const { formIsValid } = useFormValidity(...missingWords)
    const { modalState, handleModalOpen, handleModalClose } = useModal()
    const { checkbox, modalContentStatus, toggleCheckbox, handleModalStatus } = useDisclaimer()
    const { encryptString } = useEncrypt()
    const { handleSubmit } = useSubmit()

    const handlePastePassphrase = () => {
        if (!navigator.clipboard.readText) return

        navigator.clipboard.readText().then(text => {
            const copiedTextToArray = text.trim().split(" ")
            
            if (copiedTextToArray.length >= 25) {
                setMissingWords(copiedTextToArray)
            } else {
                const concatArray = copiedTextToArray.concat(Array(25 - copiedTextToArray.length).fill(''))
                setMissingWords(concatArray)
            }
            
        })
    }

    const handleChange = (e, i) => {
        const values = [...missingWords]
        values[i] = e.target.value
        setMissingWords(values)
    }

    const handleModalToDefault = () => {
        handleModalClose()
        handleModalStatus(DEFAULT)
    }

    const showDisclaimerModal = () => {
        handleModalOpen()
    }

    const storePassphraseInBrowserDB = async (res) => {
        const db = new DB()
        const data = { [res.address]: encryptString(res.passphrase), type: 'algorand' }
        const response = await db.addPassphrase(data)
        return response
    }

    const submitSuccess = (res) => {
        handleModalStatus(SUCCESS)
        handleModalOpen()
        storePassphraseInBrowserDB(res)
    }

    const submitError = () => {
        handleModalStatus(ERROR)
        handleModalOpen()
    }

    const handleImportWallet = () => {
        handleModalToDefault()

        const missingWords2 = missingWords.map(word => word.trim())
        const phrase = missingWords2.join(' ')
        
        const importData = { status: IMPORT, phrase: phrase, active: true }

        handleSubmit(createAlgorandWallet(importData), submitSuccess, submitError)
    }

    const navigateToDashboard = () => {
        handleModalToDefault()
        dispatch(getActiveAlgorandWallet())
        history.push('/wallet')
    }

    return (
        <AuthWrapper>
            <WalletWrapper
                title="IMPORT YOUR PASSPHRASE"
                description="Please fill in your Algorand wallet 25 words passphrase to restore your wallet."
                link={`/create-wallet?wallet=${ALGO}`}
            >
                <Styles.Container>
                    <Styles.WordsBoxImport hideOnMobile>
                    {
                        missingWords.map((_, i) => (
                            <Styles.WordInput key={i} style={{ display: i > 24 ? 'none' : 'block' }}>
                                <span>{i+1}.</span>
                                <input value={missingWords[i]} onChange={e => handleChange(e, i)} />
                            </Styles.WordInput>
                        ))
                    }
                    </Styles.WordsBoxImport>

                    <Styles.WordsBoxImport>
                    {
                        <Grid container>
                            <Grid item container xs={6}>
                                <Grid item xs={12} >
                                    { missingWords.slice(0, 15).map((_, i) => (
                                        <Styles.NumberedInput key={i} style={{ display: i > 24 ? 'none' : 'block' }}>
                                            {i+1}.
                                            <Styles.Input value={missingWords[i]} onChange={e => handleChange(e, i)} />
                                        </Styles.NumberedInput>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item container xs={6}>
                                <Grid item xs={12}>
                                    { missingWords.slice(15, missingWords.length).map((_, i) => (
                                        <Styles.NumberedInput key={i} style={{ display: i > 9 ? 'none' : 'block' }}>
                                            {i+16}.
                                            <Styles.Input value={missingWords[i+15]} onChange={e => handleChange(e, i+15)} />
                                        </Styles.NumberedInput>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    </Styles.WordsBoxImport>
                
                    <Styles.ButtonsContainer>
                        {
                            navigator.clipboard.readText && (
                                <CopyButton outlined onClick={handlePastePassphrase}>
                                    paste <NoteOutlinedIcon fontSize="small" sx={{ ml: '7px', transform: 'rotate(90deg)' }} />
                                </CopyButton> 
                            )
                        }
                            
                        <Button fullWidth disabled={!formIsValid} onClick={showDisclaimerModal}>verify my backup</Button>
                    </Styles.ButtonsContainer>

                </Styles.Container>

                <Modal open={modalState} handleOpen={handleModalOpen} handleClose={handleModalToDefault} fullScreenForMobile>
                    <Styles.ModalContent>
                    {
                        modalContentStatus === DEFAULT && (
                            <DisclaimerDefault
                                checkbox={checkbox}
                                toggleCheckbox={toggleCheckbox}
                                handleContinue={handleImportWallet}
                                handleCloseModal={handleModalToDefault}
                            />
                        )
                    }
                    {
                        modalContentStatus === SUCCESS && (
                            <DisclaimerSuccess handleClick={navigateToDashboard} />
                        )
                        
                    }
                    {
                        modalContentStatus === ERROR && (
                            <DisclaimerError />
                        )
                    }
                    </Styles.ModalContent>
                </Modal>
            </WalletWrapper>
        </AuthWrapper>
    )
}

export default ImportWallet

import styled from 'styled-components'


export const Form = styled.form`
    margin-top: 10px;
`

export const Title = styled.h1`
    color: #043923;
    text-transform: uppercase;
    margin-bottom: 10px;
`

export const ForgotPassword = styled.div`
    margin-left: auto;
    margin-top: 20px;
    color: #043923;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
`

export const ModalTitle = styled.h2`
    color: #043923;
    margin-bottom: 10px;
    text-align: center;
`

export const Text = styled.p`
    color: #043923;
    font-size: 15px;
    
`
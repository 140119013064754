import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../components/UI/Button/button";
import * as Styles from "./landingPageTop";

function LandingPageTop() {
    return (
        <Styles.LandingTopRoot>
            <Styles.Container>
                <h1>Myrkle</h1>
                <h2>A comprehensive suite of web3 instruments spread across an ecosystem of distributed ledgers.</h2>
                <Grid container className="gridContainer" alignItems="center">
                    <Grid item xs={12} md={6}>
                        <p>A gateway that connects next generation distributed ledgers.</p>
                    </Grid>
                    <Grid item xs={12} md={6} className="arrowBoundary" style={{ overflow: 'hidden'}}>
                        <Styles.Arrow>
                            <div className="point" />
                            <div className="block" />
                        </Styles.Arrow>
                    </Grid>
                    <Grid item xs={12} md={6} className="arrowBoundary" style={{ overflow: 'hidden', paddingBottom: '20px'}}>
                        <Styles.LeftArrowContainer>
                            <Styles.LeftArrow>
                                <div className="point" />
                                <div className="block" />
                            </Styles.LeftArrow>
                        </Styles.LeftArrowContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <p>Enabling interoperability between next generations distributed ledgers with the aid of a user friendly and streamlined interface.</p>
                    </Grid>
                </Grid>
                <div className="buttonsContainer">
                    <Link to="/signup">
                        <Button style={{marginRight: '20px'}}>get started</Button>
                    </Link>
                    <Link to="/login">
                        <Button outlined>login</Button>
                    </Link>
                </div>
            </Styles.Container>
        </Styles.LandingTopRoot>
    )

}

export default LandingPageTop;
